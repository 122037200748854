<template>
  <div class="main">
    <div class="placeholder"></div>
    <div class="job-box-wrap">
      <div class="job-box">
        <div class="job-content">
          <div class="job-content-left">
            <div class="job-title">腾讯会议iOS/Mac高级研发工程师</div>
            <div class="job-tips">
              <span>厦门</span> | <span>电销，网销</span> |
              <span>2021-12-21</span>
            </div>

            <div class="job-duty">
              <p class="job-duty-title">工作职责</p>
              <div class="job-duty-text">
                <div>1、负责腾讯会议 iOS 终端功能模块开发或架构优化工作；</div>
                <div>2、负责高质量的方案设计和编码，不断迭代重构；</div>
                <div>
                  3、负责项目重点、难点的技术攻坚以及移动端新技术预研等。
                </div>
              </div>
            </div>

            <div class="job-require">
              <p class="job-duty-title">工作要求</p>
              <div class="job-duty-text">
                <div>1、负责腾讯会议 iOS 终端功能模块开发或架构优化工作；</div>
                <div>2、负责高质量的方案设计和编码，不断迭代重构；</div>
                <div>
                  3、负责项目重点、难点的技术攻坚以及移动端新技术预研等。
                </div>
              </div>
            </div>
          </div>

          <div class="job-content-right">
            <!-- 投递信息 -->
            <div class="job-deliver-info">
              <div class="job-deliver-item"><span>联系人:</span>XXXX</div>

              <div class="job-deliver-item">
                <span>邮箱:</span>784415702@qq.com
              </div>

              <div class="job-deliver-item">
                <span>手机:</span>15165616384
              </div>

              <div class="job-deliver-item">
                <span>地址:</span>厦门市思明区软件园二期软件园生活广场B坐611
              </div>

              <div class="job-deliver-item layui-btn layui-btn-normal">
                立即投递简历
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "jobinfo",
  data() {
    return {};
  },
  mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("../assets/css/public.css");
.placeholder {
  height: 80px;
  min-width: 1200px;
  background-color: #094a99;
}
.job-box-wrap {
  width: 100%;
}

.job-box {
  width: 1200px;
  padding: 0px 0px 40px;
  margin: 0 auto;
}
.job-content {
  width: 100%;
}
.job-content-left {
  width: 70%;
  margin: 0 auto;
  padding-bottom:320px; margin-bottom:-300px; 
}
.job-title {
  font-size: 36px;
  color: #333333;
  line-height: 44px;
  margin-right: 4%;
  font-weight: 600;
}
.job-tips {
  font-size: 24px;
  color: #333333;
  line-height: 24px;
  margin-top: 16px;
  padding-bottom: 40px;
  border-bottom: 1px solid #d8d8d8;
}
.job-duty,
.job-require {
  margin-top: 40px;
}
.job-duty-title {
  font-size: 28px;
  color: #333333;
  line-height: 28px;
}
.job-duty-text {
  margin-top: 20px;
  font-size: 16px;
  color: #333333;
  line-height: 28px;
}
.job-content-right,.job-content-left{
display: inline-block;
vertical-align: top;
padding-top: 40px;

}
.job-content-right {
  width: 30%;
  padding-bottom: 40px;
  /* background-color: rgb(217, 236, 255); */
  padding-bottom:320px; margin-bottom:-300px; 
}
.job-deliver-info {
  padding: 0 30px;
  font-size: 16px;
  color: #333333;
  line-height: 28px;
}
.job-deliver-item {
  margin: 6px 0;
}

</style>
